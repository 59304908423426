import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Compass = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    fill="currentColor"
  >
    <path d="M9 0C4.03476 0 -1.64699e-05 4.03479 0 9C-1.64699e-05 13.9653 4.03476 18 9 18C13.9652 18 18 13.9653 18 9C18 4.03479 13.9652 0 9 0ZM9 0.9C13.4788 0.9 17.1 4.52124 17.1 9C17.1 13.4788 13.4788 17.1 9 17.1C4.52118 17.1 0.899985 13.4788 0.9 9C0.899985 4.52124 4.52118 0.9 9 0.9Z" />
    <path d="M9.90156 9.00059C9.90156 9.23928 9.80674 9.46824 9.63796 9.63702C9.46918 9.80581 9.24026 9.90059 9.00156 9.90059C8.76287 9.90059 8.53395 9.80581 8.36517 9.63702C8.19638 9.46824 8.10156 9.23928 8.10156 9.00059C8.10156 8.76189 8.19638 8.53304 8.36517 8.36426C8.53395 8.19548 8.76287 8.10059 9.00156 8.10059C9.24026 8.10059 9.46918 8.19548 9.63796 8.36426C9.80674 8.53304 9.90156 8.76189 9.90156 9.00059Z" />
    <path d="M4.47764 4.9525C4.39997 4.9565 4.32466 4.98059 4.25906 5.02237C4.19346 5.06415 4.13981 5.1222 4.10333 5.1909C4.06686 5.25959 4.04881 5.33661 4.05095 5.41436C4.05309 5.49211 4.07534 5.56794 4.11553 5.63453L7.17413 10.6759C7.22751 10.7639 7.3094 10.8309 7.40616 10.8658L13.3476 13.0244C13.4349 13.056 13.5298 13.0598 13.6194 13.0354C13.709 13.0109 13.7888 12.9593 13.8479 12.8877C13.907 12.8161 13.9425 12.7279 13.9495 12.6353C13.9566 12.5428 13.9348 12.4502 13.8872 12.3705L10.8937 7.36246C10.8406 7.27386 10.7587 7.20621 10.6616 7.17086L4.65518 4.97886C4.59835 4.95833 4.53797 4.94952 4.47764 4.95283V4.9525ZM5.54991 6.26382L10.2028 7.96187L12.4651 11.7464L7.86143 10.073L5.54991 6.26382V6.26382Z" />
    <path d="M8.55078 1.7998V3.5998H9.45078V1.7998H8.55078Z" />
    <path d="M14.3984 8.5498V9.4498H16.1984V8.5498H14.3984Z" />
    <path d="M8.55078 14.4004V16.2004H9.45078V14.4004H8.55078Z" />
    <path d="M1.80078 8.5498V9.4498H3.60078V8.5498H1.80078Z" />
  </svg>, 'Compass'
);

export default Compass;
