import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useState, useEffect } from 'react';

const Logo = (props) => {
  const { logo } = props;
  const [logoImage, setLogoImage] = useState('/static/images/mymentor_logo_text.svg');

  useEffect(() => {
    if (logo && logo === 'white') {
      setLogoImage('/static/images/mymentor_logo_text_white.svg');
    }
  }, []);

  return (
    <Box
      alt="myMentor"
      component="img"
      src={logoImage}
      sx={{ mr: 1 }}
      {...props}
    />
  );
};

Logo.propTypes = {
  logo: PropTypes.string
};

export default Logo;
