import axios from 'axios';

const url = `${process.env.REACT_APP_SERVER}`;

class AuthApi {
  async login({ email, password }) {
    const bodyRequest = {
      userName: email,
      password
    };

    try {
      const response = await axios.post(`${url}account/login`, bodyRequest);
      const { data } = response;
      if (data.error && data.exceptionMessages) {
        throw Error(data.exceptionMessages.messages[0]);
      } else {
        return response.data.value.accessToken;
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        if (data.exceptionMessages.hasMessages) {
          throw Error(data.exceptionMessages.messages[0]);
        }
      }
      throw new Error(error.message);
    }
  }

  async register(userRegisterData) {
    const bodyRequest = userRegisterData;

    try {
      const response = await axios.post(`${url}users`, bodyRequest);
      const { data } = response;
      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        if (data.exceptionMessages.hasMessages) {
          throw Error(data.exceptionMessages.messages[0]);
        }
      }
      throw new Error(error.message);
    }
  }

  me(accessToken) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    const encodedPayload = accessToken.split('.')[1];
    const payload = JSON.parse(atob(encodedPayload));

    return axios.get(`${url}users/${payload.user_id}`, config).then((response) => {
      const { data } = response;
      return data.value;
    }).catch((error) => {
      console.error('[Auth Api]: ', error, error.response.data.Description);
      if (error.response) {
        const { status, statusText } = error.response;
        if (status === 401) {
          console.warn('statusText 401', statusText);
        } else if (status === 500) {
          console.warn('statusText 500', statusText);
          if (error.response && error.response.data && error.response.data.Description && error.response.data.Description === 'Error : The session expired.') {
            console.warn('Session Expired.');
            return 'Session Expired';
          }
        }
      }
      return {};
    });
  }
}

export const authApi = new AuthApi();
