import axios from 'axios';

const url = `${process.env.REACT_APP_SERVER}users`;

class UserApi {
  saveUserPersonalInfo(id, firstName, lastName, birthDate, naturality, districtId, countyId) {
    const accessToken = window.localStorage.getItem('accessToken');
    if (accessToken) {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      const bodyRequest = { id, firstName, lastName, naturality, birthDate: new Date(birthDate), districtId, countyId };
      return axios.post(`${url}/${id}/personal-info`, bodyRequest, config).then((response) => response.data.value).catch((err) => {
        console.error('err', err);
        return 'NOK';
      });
    }
    return null;
  }

  saveUserProfessionalInfo(id, employmentStatusId, educationalQualificationId, interestAreaId, isLookingForJob, isLookingForFormations) {
    const accessToken = window.localStorage.getItem('accessToken');
    if (accessToken) {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      const bodyRequest = { id, employmentStatusId, educationalQualificationId, interestAreaId, isLookingForJob: isLookingForJob === '1', isLookingForFormations: isLookingForFormations === '1' };
      return axios.post(`${url}/${id}/profissional-info`, bodyRequest, config).then((response) => response.data.value).catch((err) => {
        console.error('err', err);
        return 'NOK';
      });
    }
    return null;
  }

  saveUserAccountDefs(id, emailUser, passwordUser, oldPasswordUser) {
    const accessToken = window.localStorage.getItem('accessToken');
    if (accessToken) {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      const bodyRequest = { id, email: emailUser, oldPassword: oldPasswordUser, password: passwordUser };
      return axios.post(`${url}/${id}/account-info`, bodyRequest, config).then((response) => response.data.value)
        .catch((error) => {
          console.error('err', error);
          const { status } = error.response;
          if (status === 400) {
            return 'Palavra-Passe incorrecta';
          }
          if (status === 409) {
            return 'Email existente';
          }
          return 'NOK';
        });
    }
    return null;
  }

  saveUserNotifications(id, newFormations, newJobOpportunities, actionPlanReminder, newArticlesForGettingReady, personalDataCollection, personalDataProcessing, sharingDataWithPartners) {
    const accessToken = window.localStorage.getItem('accessToken');
    if (accessToken) {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      const bodyRequest = { id, newFormations, newJobOpportunities, actionPlanReminder, newArticlesForGettingReady, personalDataCollection, personalDataProcessing, sharingDataWithPartners };
      return axios.post(`${url}/${id}/notification-info`, bodyRequest, config).then((response) => response.data.value)
        .catch((error) => {
          console.error('err', error);
          return 'NOK';
        });
    }
    return null;
  }

  getActionPlanShort(id) {
    const accessToken = window.localStorage.getItem('accessToken');
    if (accessToken) {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      return axios.get(`${url}/${id}/action-plan/shortened`, config).then((response) => response.data.value)
        .catch((error) => {
          console.error('err', error);
          return 'NOK';
        });
    }
    return null;
  }

  getActionPlanQualify(id) {
    const accessToken = window.localStorage.getItem('accessToken');
    if (accessToken) {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      return axios.get(`${url}/${id}/action-plan/qualify`, config).then((response) => response.data.value)
        .catch((error) => {
          console.error('err', error);
          return 'NOK';
        });
    }
    return null;
  }

  saveActionPlan(id, qualifyObject) {
    const accessToken = window.localStorage.getItem('accessToken');
    if (accessToken) {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      const bodyRequest = qualifyObject;
      return axios.post(`${url}/${id}/action-plan`, bodyRequest, config).then((response) => response.data.value)
        .catch((error) => {
          console.error('err', error);
          return 'NOK';
        });
    }
    return null;
  }

  saveKnowledgeTest(userId, testId, answers) {
    const accessToken = window.localStorage.getItem('accessToken');
    if (accessToken) {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      const bodyRequest = { type: testId, answers };
      return axios.post(`${url}/${userId}/knowledge-test-results`, bodyRequest, config).then((response) => response.data.value)
        .catch((error) => {
          console.error('err', error);
          return 'NOK';
        });
    }
    return null;
  }

  getKnowledgeTest(userId, testId) {
    const accessToken = window.localStorage.getItem('accessToken');
    if (accessToken) {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      return axios.get(`${url}/${userId}/knowledge-test-results/${testId}`, config).then((response) => response.data.value)
        .catch((error) => {
          console.error('err', error);
          return 'NOK';
        });
    }
    return null;
  }

  getActionPlanBookmarks(id) {
    const accessToken = window.localStorage.getItem('accessToken');
    if (accessToken) {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      return axios.get(`${url}/${id}/action-plan/bookmarks`, config).then((response) => response.data.value)
        .catch((error) => {
          console.error('err', error);
          return 'NOK';
        });
    }
    return null;
  }

  getActionPlanKnowledgeTests(id) {
    const accessToken = window.localStorage.getItem('accessToken');
    if (accessToken) {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      return axios.get(`${url}/${id}/action-plan/knowledge-tests`, config).then((response) => response.data.value)
        .catch((error) => {
          console.error('err', error);
          return 'NOK';
        });
    }
    return null;
  }
}

export const userApi = new UserApi();
