import { createSlice } from '@reduxjs/toolkit';
import { userApi } from '../apiCalls/userApi';

const initialState = {
  actionPlanObj: {}
};

const slice = createSlice({
  name: 'actionPlan',
  initialState,
  reducers: {
    getActionPlan(state, action) {
      state.actionPlanObj = action.payload;
    },
  }
});

export const { reducer } = slice;

export const getActionPlan = (userID) => async (dispatch) => {
  const data = await userApi.getActionPlanShort(userID);
  dispatch(slice.actions.getActionPlan(data));
};

export default slice;
