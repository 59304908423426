import {
  Box,
  Container,
  Grid,
  Link,
  Typography
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const links = [
  {
    title: 'O que é myMentor',
    href: '/oqueemymentor'
  },
  {
    title: 'Termos e Condições',
    href: '/termos-condicoes'
  },
  {
    title: 'FAQ',
    href: '/faq'
  },
];
const imgStyle = { mr: 1, height: 40, maxWidth: '100%' };
const imgStyleAux = { height: 20, maxWidth: '100%' };
const Footer = (props) => (
  <Box
    sx={{
      background: '#fff',
      pt: 2,
      pb: 2,
    }}
    {...props}
  >
    <Container maxWidth="lg">
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column', sm: 'row' } }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {links.map((link) => (
                <RouterLink
                  key={link.href}
                  to={link.href}
                  style={{ textDecoration: 'none' }}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle1"
                    component="span"
                    sx={{ pr: 2, fontWeight: 500, whiteSpace: 'nowrap' }}
                  >
                    {link.title}
                  </Typography>
                </RouterLink>
              ))}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
              <Link
                href="https://facebook.com"
                target="_blank"
              >
                <Box
                  alt="Facebook"
                  component="img"
                  src="/static/images/social/fb32.png"
                  sx={{ mr: 1, maxHeight: 32 }}
                />
              </Link>
              <Link
                href="https://instagram.com"
                target="_blank"
              >
                <Box
                  alt="Instagram"
                  component="img"
                  src="/static/images/social/instagram32.png"
                  sx={{ mr: 1, maxHeight: 32 }}
                />
              </Link>
              <Link
                href="https://linkedin.com"
                target="_blank"
              >
                <Box
                  alt="Linkedin"
                  component="img"
                  src="/static/images/social/linkedin32.png"
                  sx={{ mr: 1, maxHeight: 32 }}
                />
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={7}
          xs={12}
        >
          <Typography color="primary">Cofinanciado por</Typography>
          <Box>
            <Box
              alt="Portugal Inovação Social"
              component="img"
              src="/static/images/logos/PortugalInovacaoSocial.png"
              sx={imgStyle}
            />
            <Box
              alt="Cofinanciado"
              component="img"
              src="/static/images/logos/cofinanciado.png"
              sx={imgStyle}
            />
          </Box>
          <Box>
            <Box
              alt="Fundação Calouste Gulbenkian"
              component="img"
              src="/static/images/logos/gulbenkian.png"
              sx={imgStyle}
            />
            <Box
              alt="Santander Fundação"
              component="img"
              src="/static/images/logos/santanderFund.png"
              sx={imgStyle}
            />
            <Box
              alt="Cascais"
              component="img"
              src="/static/images/logos/cascais.png"
              sx={imgStyle}
            />
          </Box>
        </Grid>
        <Grid
          item
          md={2}
          sm={4}
          xs={6}
        >
          <Typography color="primary">Promovido por</Typography>
          <Box
            alt="BetterFuture"
            component="img"
            src="/static/images/logos/betterfuture.jpg"
            sx={imgStyle}
          />
        </Grid>
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
        >
          <Typography color="primary">Parceiros</Typography>
          <Box
            alt="IEFP"
            component="img"
            src="/static/images/logos/iefp.jpg"
            sx={imgStyle}
          />
          <Box
            alt="ANQEP"
            component="img"
            src="/static/images/logos/anqep.png"
            sx={imgStyle}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Box sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'row' }}>
            <Typography sx={{ mr: 1 }}>Desenvolvido por </Typography>
            <Link
              href="https://www.bi4all.pt/"
              target="_blank"
              sx={{ height: 20 }}
            >
              <Box
                alt="BI4ALL"
                component="img"
                src="/static/images/logos/BI4ALL.png"
                sx={imgStyleAux}
              />
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Container>
  </Box>
);

export default Footer;
