import { Box, Typography } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';

function LinearProgressWithLabel(props) {
  const { value } = props;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          value={value}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="text.primary"
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const ActionPlanDrawerProgress = (props) => {
  const { actionPlanPerc = 0 } = props;

  return (
    <LinearProgressWithLabel value={actionPlanPerc} />
  );
};

ActionPlanDrawerProgress.propTypes = {
  actionPlanPerc: PropTypes.number.isRequired,
};

export default ActionPlanDrawerProgress;
