import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Rocket = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    fill="currentColor"
  >
    <path d="M17.1264 0.580172C17.0911 0.451839 16.9908 0.351543 16.8625 0.316299C15.1819 -0.145337 13.305 -0.100962 11.4345 0.444859C9.57901 0.986264 7.90069 1.97002 6.5809 3.28981C6.32179 3.54896 6.07418 3.82498 5.84294 4.11199C4.96578 4.13044 4.09424 4.47265 3.42635 5.14054L0.645667 7.92122C0.54869 8.0182 0.511975 8.16023 0.549785 8.29207C0.587594 8.42391 0.693966 8.52489 0.827657 8.55572L4.3371 9.3656L5.10604 10.1345L2.31411 12.9264C2.16676 13.0738 2.16676 13.3127 2.31411 13.4601C2.3878 13.5338 2.48436 13.5706 2.58092 13.5706C2.67749 13.5706 2.77408 13.5338 2.84774 13.4601L5.63971 10.6682L8.07705 13.1055L8.88694 16.615C8.91776 16.7487 9.01874 16.8551 9.15058 16.8929C9.18488 16.9027 9.21986 16.9075 9.25458 16.9075C9.35325 16.9075 9.44966 16.8688 9.52143 16.797L12.3021 14.0163C12.9504 13.368 13.3148 12.5116 13.3332 11.5976C13.6192 11.367 13.8944 11.1202 14.1529 10.8618C15.4727 9.54197 16.4564 7.86364 16.9978 6.00815C17.5436 4.13769 17.588 2.26073 17.1264 0.580172ZM3.96002 5.67417C4.94907 4.68509 6.50623 4.60219 7.59059 5.42547L4.40849 8.60757L1.66075 7.97349L3.96002 5.67417ZM11.7685 13.4827L9.46917 15.782L8.83509 13.0342L12.0174 9.85195C12.3591 10.3006 12.5538 10.8397 12.577 11.4095C12.5768 11.4207 12.577 11.4319 12.5778 11.443C12.5786 11.4703 12.5794 11.4976 12.5794 11.525C12.5794 12.2645 12.2914 12.9598 11.7685 13.4827ZM13.6193 10.3281C13.4953 10.4521 13.3666 10.5727 13.235 10.6904C13.0846 10.0683 12.7662 9.4976 12.3021 9.03354C12.1547 8.88623 11.9159 8.88623 11.7684 9.03354L8.42036 12.3816L5.06102 9.0223L8.40911 5.67421C8.55646 5.52686 8.55646 5.28793 8.40911 5.14054C7.93525 4.66668 7.35883 4.35643 6.75067 4.20946C6.86885 4.07727 6.98998 3.948 7.1145 3.82348C9.55449 1.38349 13.3623 0.236379 16.4529 0.989736C17.2061 4.08025 16.0592 7.88817 13.6193 10.3281Z" />
    <path d="M10.658 3.47144C9.74422 4.38524 9.74422 5.87203 10.658 6.78584C11.1149 7.24268 11.715 7.47116 12.3151 7.47116C12.9153 7.47116 13.5155 7.24268 13.9723 6.78584C14.8861 5.87203 14.8861 4.38524 13.9723 3.47144C13.0585 2.55772 11.5718 2.55768 10.658 3.47144ZM13.4387 6.25216C12.8192 6.87168 11.8112 6.87168 11.1916 6.25216C10.5721 5.63265 10.5721 4.62459 11.1916 4.00507C11.5014 3.69532 11.9083 3.54046 12.3151 3.54046C12.722 3.54046 13.1289 3.69532 13.4387 4.00507C14.0583 4.62463 14.0583 5.63265 13.4387 6.25216Z" />
    <path d="M5.35122 12.6486C5.20387 12.5013 4.96494 12.5013 4.81755 12.6486L0.110513 17.3556C-0.0368377 17.503 -0.0368377 17.7419 0.110513 17.8893C0.184207 17.963 0.280768 17.9998 0.377329 17.9998C0.47389 17.9998 0.570489 17.963 0.644146 17.8893L5.35122 13.1822C5.49857 13.0349 5.49857 12.7959 5.35122 12.6486Z" />
    <path d="M6.20622 13.4835L3.38395 16.3058C3.2366 16.4532 3.2366 16.6921 3.38395 16.8395C3.45764 16.9131 3.55421 16.95 3.65077 16.95C3.74733 16.95 3.84393 16.9131 3.91758 16.8395L6.73985 14.0172C6.8872 13.8699 6.8872 13.6309 6.73985 13.4835C6.5925 13.3362 6.35357 13.3362 6.20622 13.4835Z" />
  </svg>, 'Rocket'
);

export default Rocket;
