import { Box, Typography, Paper, Button } from '@material-ui/core';
import Logo from './Logo';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const RegisterWall = (props) => {
  const { hasBackground } = props;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: hasBackground === false ? '' : 'linear-gradient(0deg, #E1E6EA 0%, rgba(110, 110, 110, 0.15) 100%)',
        zIndex: 10,
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        pt: '10%'
      }}
    >
      <Paper sx={{ backgroundColor: '#fff', p: 3, maxWidth: 400, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Logo sx={{ maxWidth: 200 }} />
        <Typography
          variant="h4"
          mt={4}
        >
          Registe-se agora!
        </Typography>
        <Typography
          my={4}
          textAlign="center"
        >
          Por favor registe-se na plataforma para poder aceder livremente a todas as funcionalidades da plataforma.
        </Typography>
        <Button
          variant="contained"
          component={RouterLink}
          to="/registar"
        >
          Registar
        </Button>
        <Typography
          mt={2}
          mb={2}
          component={RouterLink}
          to="/login"
        >
          Já tem conta?
        </Typography>
      </Paper>
    </Box>
  );
};

RegisterWall.propTypes = {
  hasBackground: PropTypes.bool
};

export default RegisterWall;
