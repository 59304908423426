import { useState, useEffect } from 'react';
import { Outlet, Link as RouterLink } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import SideMenuNavbar from './SideMenuNavbar';
import SideMenuSidebar from './SideMenuSidebar';
import Footer from '../Footer';
import ActionPlanDrawer from '../actionPlanDrawer/ActionPlanDrawer';
import useAuth from '../../hooks/useAuth';
import { getActionPlan } from '../../slices/actionPlan';
import { useSelector, useDispatch } from '../../store';
import _ from 'lodash';
import CookieConsent from 'react-cookie-consent';

const SideMenuLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const SideMenuLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '64px',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '220px',
    paddingTop: '0px'
  }
}));

const SideMenuLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const SideMenuLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
  transition: 'padding 0.3s ease-in-out'
});

const SideMenuLayout = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  const [actionPlan, setActionPlan] = useState(false);
  const [actionStyle, setActionStyle] = useState({});
  const [actionPlanPerc, setActionPlanPerc] = useState(0);
  const widthDrawer = 300;
  const auth = useAuth();
  const dispatch = useDispatch();
  const { actionPlanObj } = useSelector((state) => state.actionPlan);

  useEffect(() => {
    if (!_.isEmpty(actionPlanObj)) {
      let sumActionPlan = 0;
      sumActionPlan += actionPlanObj.bookmarks?.articles.length > 0 ? 1 : 0;
      sumActionPlan += actionPlanObj.bookmarks?.offers.length > 0 ? 1 : 0;
      sumActionPlan += actionPlanObj.knowledgeTests?.length > 0 ? 1 : 0;
      sumActionPlan += actionPlanObj.qualify?.trainings.length > 0 ? 1 : 0;
      setActionPlanPerc((sumActionPlan / 4) * 100);
    }
  }, [actionPlanObj]);

  const handleOpen = (open) => {
    setActionPlan(open);
    if (open) {
      setActionStyle({ paddingRight: ` ${widthDrawer}px` });
    } else {
      setActionStyle({});
    }
  };

  useEffect(() => {
    if (auth.isInitialized) {
      if (auth.isAuthenticated) {
        dispatch(getActionPlan(auth.user.id));
      }
    }
  }, [auth.isInitialized]);

  return (
    <SideMenuLayoutRoot>
      <SideMenuNavbar
        onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)}
        onSidebarMobileActionPlanOpen={() => setActionPlan(true)}
      />
      <SideMenuSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      <SideMenuLayoutWrapper>
        <SideMenuLayoutContainer>
          <SideMenuLayoutContent
            className="dashboardLayoutContent"
            sx={actionStyle}
          >
            <Outlet />
            <CookieConsent
              style={{ zIndex: 9999, background: 'linear-gradient(257.68deg, rgba(192, 35, 239, 1) 0%, rgba(62, 0, 131, 1) 100.15%)' }}
              buttonText="Fechar"
              buttonStyle={{
                background: 'rgba(0,0,0,0.5)',
                border: '1px solid #fff',
                color: '#fff',
                fontWeight: 600,
                fontSize: '0.875rem',
                lineHeight: 1.75,
                padding: '6px 16px',
                borderRadius: 16,
                textTransform: 'uppercase',
              }}
            >
              ATENÇÃO! Este site utiliza cookies. Ao navegar no site estará a consentir a sua utilização.&nbsp;
              <RouterLink
                style={{ color: '#fff' }}
                to="/cookies"
              >
                Saiba mais
              </RouterLink>
              &nbsp;
              sobre o uso de cookies.
            </CookieConsent>
            <Footer />
            { auth.isInitialized
              ? (
                <ActionPlanDrawer
                  openHandle={handleOpen}
                  open={actionPlan}
                  widthDrawer={widthDrawer}
                  onMobileClose={() => setActionPlan(false)}
                  openMobile={actionPlan}
                  actionPlanObj={actionPlanObj}
                  actionPlanPerc={actionPlanPerc}
                  isAuthenticated={auth.isAuthenticated}
                />
              )
              : null }
          </SideMenuLayoutContent>
        </SideMenuLayoutContainer>
      </SideMenuLayoutWrapper>
    </SideMenuLayoutRoot>
  );
};

export default SideMenuLayout;
