import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import Splash from './SplashPage';

const IsInitializedAuthGuard = (props) => {
  const { children } = props;
  const auth = useAuth();

  return (
    <>
      { auth.isInitialized ? children : <Splash />}
    </>
  );
};

IsInitializedAuthGuard.propTypes = {
  children: PropTypes.node
};

export default IsInitializedAuthGuard;
