import { Box, IconButton, Typography, Collapse, Divider } from '@material-ui/core';
import ChevronDownIcon from '../../../icons/ChevronDown';
import ChevronUpIcon from '../../../icons/ChevronUp';
import PropTypes from 'prop-types';
import { useState } from 'react';

const ActionPlanDrawerPrepare = (props) => {
  const { actionPlanObj } = props;
  const activities = actionPlanObj?.knowledgeTests?.length || 0;
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'stretch', justifyContent: 'space-between', py: 2 }}
      >
        <Box
          sx={{ height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Typography
            variant="h4"
            component="p"
          >
            {activities}
          </Typography>
        </Box>
        <Box
          sx={{ width: 145 }}
        >
          <Typography sx={{ fontSize: '1.2rem', fontWeight: '600', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            Conhecer-me
          </Typography>
          <Typography sx={{ fontSize: '0.7rem', color: '#8D99AE' }}>
            {activities}
            &nbsp;
            Atividades
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {activities > 0 && (
            <IconButton
              size="small"
              onClick={handleExpandClick}
            >
              {expanded ? <ChevronUpIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" /> }
            </IconButton>
          )}
        </Box>
      </Box>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <Divider sx={{ mb: 1 }} />
        {actionPlanObj?.knowledgeTests?.length && actionPlanObj.knowledgeTests.map((m) => (
          <Typography
            variant="body2"
            fontWeight={500}
            key={m.name}
          >
            &bull;&nbsp;
            {m.name}
          </Typography>
        ))}
        <Divider sx={{ mt: 1 }} />
      </Collapse>
    </>
  );
};

ActionPlanDrawerPrepare.propTypes = {
  actionPlanObj: PropTypes.object
};

export default ActionPlanDrawerPrepare;
