import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollResetContainer = (scrollContainer) => {
  const location = useLocation();

  useEffect(() => {
    if (scrollContainer && document.querySelector(scrollContainer)) {
      document.querySelector(scrollContainer).scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location.pathname]);

  return null;
};

export default useScrollResetContainer;
