import PropTypes from 'prop-types';
import { Box, Tooltip, Typography, Drawer, Button } from '@material-ui/core';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import ClipboardListIcon from '../../icons/ClipboardList';
import { ActionPlanDrawerHeader, ActionPlanDrawerProgress, ActionPlanDrawerKnowMe, ActionPlanDrawerPrepare, ActionPlanDrawerQualify, ActionPlanDrawerGetJob } from './sections';
import { useEffect, useState } from 'react';
import RegisterWall from '../RegisterWall';

const ActionPlanDrawer = (props) => {
  const { onMobileClose, openMobile, openHandle, open, widthDrawer, actionPlanObj, actionPlanPerc, isAuthenticated } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const [scrollBarWidth, setScrollBarWidth] = useState(17);
  const handleOpen = () => {
    openHandle(true);
  };

  const handleClose = () => {
    openHandle(false);
  };

  useEffect(() => {
    if (openMobile && onMobileClose && !lgUp) {
      onMobileClose();
    }
  }, [location.pathname]);

  const isOpen = () => {
    if (open) {
      return -widthDrawer;
    }
    return 0;
  };

  const isDrawerOpen = () => {
    if (open) {
      return `calc( 100% - ${widthDrawer + scrollBarWidth}px)`;
    }
    return '100%';
  };

  useEffect(() => {
    if (document.querySelector('.dashboardLayoutContent')) {
      const element = document.querySelector('.dashboardLayoutContent');
      setScrollBarWidth(element.offsetWidth - element.clientWidth);
    }
  }, []);

  useEffect(() => {
    handleClose();
  }, [lgUp]);

  const content = (
    <>
      <ActionPlanDrawerHeader openHandle={openHandle} />
      <Box sx={{ py: 2 }}>
        <Typography
          variant="h5"
          component="p"
          sx={{ textAlign: 'center' }}
        >
          Plano de Ação
        </Typography>
      </Box>
      {isAuthenticated ? (
        <Box
          sx={{ px: 2 }}
        >
          <ActionPlanDrawerProgress actionPlanPerc={actionPlanPerc} />
          <ActionPlanDrawerKnowMe actionPlanObj={actionPlanObj} />
          <ActionPlanDrawerPrepare actionPlanObj={actionPlanObj} />
          <ActionPlanDrawerQualify actionPlanObj={actionPlanObj} />
          <ActionPlanDrawerGetJob actionPlanObj={actionPlanObj} />
          <Button
            variant="outlined"
            sx={{ width: '100%', mt: 2 }}
            component={RouterLink}
            to="/plano-acao"
          >
            Ver Plano de Ação
          </Button>
        </Box>
      ) : (
        <Box sx={{ position: 'relative', height: '100%' }}>
          <RegisterWall hasBackground={false} />
        </Box>
      )}
    </>
  );

  if (lgUp) {
    return (
      <>
        <Tooltip title="Plano de Ação">
          <Button
            color="primary"
            onClick={handleOpen}
            sx={{
              top: 0,
              margin: (theme) => theme.spacing(4),
              position: 'fixed',
              zIndex: 1,
              transition: 'right 0.3s ease-in-out',
              right: isOpen,
              boxShadow: '0px 0px 2px 0px #fff'
            }}
            variant="contained"
            component={RouterLink}
            to={useLocation()}
          >
            Plano de Ação
          </Button>
        </Tooltip>
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            width: widthDrawer,
            background: '#fff',
            padding: 2,
            left: isDrawerOpen,
            borderLeft: '1px solid #ccc',
            transition: 'left 0.3s ease-in-out'
          }}
        >
          {content}
        </Box>
      </>
    );
  }

  return (
    <Drawer
      anchor="right"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 300,
          padding: 2,
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

ActionPlanDrawer.propTypes = {
  openHandle: PropTypes.func,
  open: PropTypes.bool,
  widthDrawer: PropTypes.number,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  actionPlanObj: PropTypes.object,
  actionPlanPerc: PropTypes.number,
  isAuthenticated: PropTypes.bool
};

export default ActionPlanDrawer;
