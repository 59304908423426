import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SideMenuLayout from './components/SideMenuLayout/SideMenuLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import IsInitializedAuthGuard from './components/IsInitializedAuthGuard';
import IsAuthenticatedGuard from './components/IsAuthenticatedGuard';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Main Pages

const Home = Loadable(lazy(() => import('./pages/Home')));
const KnowMe = Loadable(lazy(() => import('./pages/KnowMe')));
const JobMarket = Loadable(lazy(() => import('./pages/prepareme/JobMarket')));
const SuccessCases = Loadable(lazy(() => import('./pages/prepareme/SuccessCases')));
const KnowledgeTest = Loadable(lazy(() => import('./pages/knowledgetests/KnowledgeTest')));
const KnowledgeTestResult = Loadable(lazy(() => import('./pages/knowledgetests/KnowledgeTestResult')));
const Qualify = Loadable(lazy(() => import('./pages/Qualify')));
const JobMap = Loadable(lazy(() => import('./pages/JobMap')));
const Entrepreneur = Loadable(lazy(() => import('./pages/Entrepreneur')));
const Professions = Loadable(lazy(() => import('./pages/explore/Professions')));
const Competences = Loadable(lazy(() => import('./pages/explore/Competences')));
const Trainings = Loadable(lazy(() => import('./pages/explore/Trainings')));
const JobOpportunities = Loadable(lazy(() => import('./pages/JobOpportunities')));
const UserProfile = Loadable(lazy(() => import('./pages/UserProfile')));
const ActionPlan = Loadable(lazy(() => import('./pages/ActionPlan')));
const NeedSupport = Loadable(lazy(() => import('./pages/NeedSupport')));
// const TestIconPage = Loadable(lazy(() => import('./pages/TestIconPage')));// Icons Test Page

const WhatsMyMentor = Loadable(lazy(() => import('./pages/FooterPages/WhatsMyMentor')));
const TermsAndConditions = Loadable(lazy(() => import('./pages/FooterPages/TermsAndConditions')));
const FAQ = Loadable(lazy(() => import('./pages/FooterPages/FAQ')));

const Cookies = Loadable(lazy(() => import('./pages/Cookies')));

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const AccountActivation = Loadable(lazy(() => import('./pages/authentication/AccountActivation')));

// Error pages
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));

const routes = [
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    )
  },
  {
    path: 'registar',
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    )
  },
  {
    path: 'verificar-conta',
    element: (
      <GuestGuard>
        <AccountActivation />
      </GuestGuard>
    )
  },
  {
    path: 'repor-palavra-passe',
    element: (
      <GuestGuard>
        <PasswordRecovery />
      </GuestGuard>
    )
  },
  {
    path: 'recuperar',
    element: (
      <GuestGuard>
        <PasswordReset />
      </GuestGuard>
    )
  },
  {
    path: '/',
    element: (
      <SideMenuLayout />
    ),
    children: [
      {
        path: '/',
        element: <Home />
      },
      // {
      //   path: '/testicon',
      //   element: <TestIconPage />
      // },
      {
        path: '/home',
        element: <Navigate to="/" />
      },
      {
        path: '/explorar/profissoes/:id',
        element: <Professions />
      },
      {
        path: '/explorar/profissoes',
        element: <Professions />
      },
      {
        path: '/explorar/competencias',
        element: <Competences />
      },
      {
        path: '/explorar/formacoes',
        element: <Trainings />
      },
      {
        path: '/mapa-emprego/:type',
        element: (
          <IsInitializedAuthGuard>
            <JobMap />
          </IsInitializedAuthGuard>
        )
      },
      {
        path: 'conhecer-me',
        element: (
          <IsInitializedAuthGuard>
            <KnowMe />
          </IsInitializedAuthGuard>
        )
      },
      {
        path: 'conhecer-me/:testId',
        element: (
          <IsInitializedAuthGuard>
            <KnowledgeTest />
          </IsInitializedAuthGuard>
        )
      },
      {
        path: 'conhecer-me/resultados/:testId',
        element: (
          <IsAuthenticatedGuard>
            <KnowledgeTestResult />
          </IsAuthenticatedGuard>
        )
      },
      {
        path: '/preparar-me/mercado-trabalho',
        element: (
          <IsInitializedAuthGuard>
            <JobMarket />
          </IsInitializedAuthGuard>
        )
      },
      {
        path: '/preparar-me/casos-sucesso',
        element: <SuccessCases />
      },
      {
        path: 'qualificar-me',
        element: (
          <IsInitializedAuthGuard>
            <Qualify />
          </IsInitializedAuthGuard>
        )
      },
      {
        path: 'empreender',
        element: <Entrepreneur />
      },
      {
        path: 'preciso-apoio',
        element: <NeedSupport />
      },
      {
        path: 'oportunidades-emprego',
        element: (
          <IsInitializedAuthGuard>
            <JobOpportunities />
          </IsInitializedAuthGuard>
        )
      },
      {
        path: 'perfil',
        element: (
          <IsAuthenticatedGuard>
            <UserProfile />
          </IsAuthenticatedGuard>
        )
      },
      {
        path: 'plano-acao',
        element: (
          <IsAuthenticatedGuard>
            <ActionPlan />
          </IsAuthenticatedGuard>
        )
      },
      {
        path: 'oqueemymentor',
        element: <WhatsMyMentor />
      },
      {
        path: 'termos-condicoes',
        element: <TermsAndConditions />
      },
      {
        path: 'faq',
        element: <FAQ />
      },
      {
        path: 'cookies',
        element: <Cookies />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
