import PropTypes from 'prop-types';
import { Box, Button, IconButton, Tooltip, Typography, Menu, MenuItem } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import useAuth from '../../../hooks/useAuth';
import { Link as RouterLink } from 'react-router-dom';
import XIcon from '../../../icons/X';
import DotsVerticalIcon from '../../../icons/DotsVertical';
import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';

function CircularProgressWithLabel(props) {
  const { value } = props;
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={value}
        sx={{ color: 'rgb(110, 53, 163)' }}
        thickness={5}
        size={50}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ fontSize: '16px' }}
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const ActionPlanDrawerHeader = (props) => {
  const { openHandle } = props;
  const { user, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCloseDrawer = () => {
    openHandle(false);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    logout();
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    setAnchorEl(null);
    navigate('/perfil', { replace: true });
  };

  return (
    <>
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Tooltip title="Fechar">
          <IconButton
            aria-label="fechar"
            onClick={handleCloseDrawer}
            size="small"
          >
            <XIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      {user
        ? (
          <Box
            sx={{ display: 'flex', alignItems: 'stretch', justifyContent: 'space-between' }}
          >
            <Box
              sx={{ width: 175 }}
            >
              <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {user.firstName}
                &nbsp;
                {user.lastName}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                aria-label="logout"
                onClick={handleMenu}
                size="small"
              >
                <DotsVerticalIcon fontSize="small" />
              </IconButton>
            </Box>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleProfile}>Perfil</MenuItem>
              <MenuItem onClick={handleLogout}>Sair</MenuItem>
            </Menu>
          </Box>
        )
        : (
          <Box>
            <Button
              variant="outlined"
              sx={{ mr: 2 }}
              component={RouterLink}
              to="/login"
              state={{ from: location.pathname }}
            >
              Entrar
            </Button>
            <Button
              variant="contained"
              component={RouterLink}
              to="/registar"
              state={{ from: location.pathname }}
            >
              Registar
            </Button>
          </Box>
        )}
    </>
  );
};

ActionPlanDrawerHeader.propTypes = {
  openHandle: PropTypes.func
};

export default ActionPlanDrawerHeader;
