import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
let disableAppInsight = true;
if (process.env.REACT_APP_INSIGHTS_KEY) {
  disableAppInsight = false;
}

const clickPluginInstance = new ClickAnalyticsPlugin();
// Click Analytics configuration
const clickPluginConfig = {
  autoCapture: true,
  dataTags: {
    useDefaultContentNameOrId: true
  }
};

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSIGHTS_KEY,
    disableTelemetry: disableAppInsight,
    disableFetchTracking: disableAppInsight,
    enableCorsCorrelation: true,
    correlationHeaderExcludedDomains: ['*.tawk.to', '*.googleapis.com', '*.landbot.io', '*.google-analytics.com'],
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableAutoRouteTracking: true,
    enableAjaxErrorStatusText: true,
    enableAjaxPerfTracking: true,
    enableUnhandledPromiseRejectionTracking: true,
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }, [clickPluginInstance.identifier]: clickPluginConfig
    }
  }
});
appInsights.loadAppInsights();
appInsights.addTelemetryInitializer((envelope) => {
  envelope.tags = envelope.tags || [];
  envelope.tags.push({ 'ai.cloud.role': 'mymentorFE' });
});
window.appInsights = appInsights;
export { reactPlugin, appInsights };
