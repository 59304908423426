import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import './i18n';
import RTL from './components/RTL';
import useScrollReset from './hooks/useScrollReset';
import useScrollResetContainer from './hooks/useScrollResetContainer';
import useSettings from './hooks/useSettings';
import routes from './routes';
import { createCustomTheme } from './theme';
import ReactGA from 'react-ga4';

function setAppInsightsUser() {
  const token = localStorage.getItem('accessToken');
  if (token) {
    const payload = JSON.parse(atob(token.split('.')[1]));
    if (payload?.email) {
      if (window.appInsights.context.user.authenticatedId !== payload?.email) {
        window.appInsights.setAuthenticatedUserContext(payload.email);
      }
    }
  } else if (window.appInsights.context.user.authenticatedId !== null) {
    window.appInsights.clearAuthenticatedUserContext();
  }
}

const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();

  useScrollReset();
  useScrollResetContainer('.dashboardLayoutContent');

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  setAppInsightsUser();

  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
  ReactGA.send('pageview');

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <ThemeProvider theme={theme}>
        <RTL direction={settings.direction}>
          <CssBaseline />
          <Toaster position="top-center" />
          {content}
        </RTL>
      </ThemeProvider>
    </AppInsightsContext.Provider>
  );
};

export default App;
