import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import Splash from './SplashPage';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const IsInitializedAuthGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const navigate = useNavigate();
  const [goNavigate, setGoNavigate] = useState(false);

  useEffect(() => {
    if (auth.isInitialized && !auth.isAuthenticated) {
      setGoNavigate(true);
    }
  }, [auth]);

  useEffect(() => {
    if (goNavigate) {
      navigate('/');
    }
  }, [goNavigate]);
  return (
    <>
      { (!auth.isInitialized || !auth.isAuthenticated) && <Splash /> }
      { auth.isInitialized && auth.isAuthenticated && children }
    </>
  );
};

IsInitializedAuthGuard.propTypes = {
  children: PropTypes.node
};

export default IsInitializedAuthGuard;
